nb-card.nb-card-checkout {
  border: none;
  min-height: 37rem;
  nb-card-body {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    div.container {
      max-width: 700px;
      div.text-loading {
        width:100%;
        text-align: center;
        font-size:1.4rem;
        line-height:2rem;
      }
    }
  }
}
