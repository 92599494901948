/* You can add global styles to this file, and also import other style files */

@use 'themes' as *;

// framework component styles
@use '@nebular/theme/styles/globals' as *;

// install the framework styles
@include nb-install() {
  @include nb-theme-global();
}

@import 'scss/buttons';

@import 'scss/checkout-component';
@import 'scss/globals';
@import 'scss/modal-loading';
@import 'scss/pending-payment';
@import 'scss/success-payment';

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/Montserrat-Regular.ttf'), url('/assets/fonts/montserrat/Montserrat-Bold.ttf'), url('/assets/fonts/montserrat/Montserrat-ExtraBold.ttf'), url('/assets/fonts/montserrat/Montserrat-Black.ttf'), url('/assets/fonts/montserrat/Montserrat-MediumBlack.ttf'), url('/assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}
