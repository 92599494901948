div#modalLoading div.modal-body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

div#modalLoading div.modal-body div.text-modal-loading {
  font-size: 1.5rem;
  margin:20px 0px;
  text-align: center;
}

/*-------- VERSION 1 ------- */
.loaderv1 {
  width: 70%;
  height: 20px;
  margin:20px 0px;
  -webkit-mask: linear-gradient(90deg,#a57c03 70%,#0000 0) left/20% 100%;
  background:
   linear-gradient(#a57c03 0 0) left -25% top 0 /20% 100% no-repeat
   #ddd;
  animation: lv2-7 1s infinite steps(6);
}
@keyframes lv2-7 {
    100% {background-position: right -25% top 0}
}

/*-------- VERSION 2 ------- */

.loaderv2 {
  width: 70%;
  height: 30px;
  margin:20px 0px;
  box-shadow: 0 3px 0 #a57c03;
  --g:repeat-x repeating-linear-gradient(90deg,#a57c03 0 16px,#0000 0 18px,#a57c03 0 34px,#0000 0 100%);
  background: var(--g),var(--g);
  background-size: 60px 14px,60px 14px;
  animation: lv4-8 1s infinite linear;
}
@keyframes lv4-8 {
  0%   {background-position:0    0,0    100%}
  100% {background-position:60px 0,60px 100%}
}
