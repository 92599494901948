button.btn-requestpass {
  background-color: #007d6c !important;
  border-color: #007d6c !important;
  color: #fff !important;
}

button.btn-registertipster {
  background-color: #ffc000 !important;
  border-color: #ffc000 !important;
  color: #000 !important;
  span {
      text-wrap: wrap;
  }
}

button.btn-registertipster:hover {
  background-color: #ffd559 !important;
  border-color: #ffd559 !important;
  color: #000 !important;
}

button.btn-registersubs {
  background-color: #505050 !important;
  border-color: #505050 !important;
  color: #fff !important;
}

button.btn-registersubs:hover {
  background-color: #969696 !important;
  border-color: #969696 !important;
  color: #fff !important;
}

button.btn-other-payments {
  background-color: #007dbb !important;
  border-color: #007dbb !important;
  color: #fff !important;
  span {
      text-wrap: wrap;
  }
}

button.btn-other-payments:hover {
  background-color: #0088cc !important;
  border-color: #0088cc !important;
  color: #fff !important;
}


