nb-card.nb-card-pending-payment {
  border: none;
  min-height: 37rem;
  nb-card-body {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    div.container {
      max-width: 700px;
      nb-card.nb-card-pending-payment-inner {
        min-height: 37rem;
        nb-card-body {
          display:flex;
          justify-content: center;
          align-items: center;
          align-content: flex-start;
          flex-wrap: wrap;
          div {
            width:100%;
          }
        }
      }
    }
  }

  section.another-action {
    margin-top: 2rem;
    text-align: center;
  }

}
